import { productApiRoute } from "../../constants/appConstants";
import { apiSlice } from "../base/apiSlice";

export const productApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: ({ type, color, brand, price, sort, name }) => `${productApiRoute}/getProducts?color=${color}&brand=${brand}&priceHigh=${price?.priceHigh}&priceLow=${price?.priceLow}&type=${type}&sort=${sort}&name=${name}`

        }),
        getProductById: builder.query({
            query: (id) => `${productApiRoute}/getProduct/${id}`
        })

    })
})
export const { useGetProductsQuery, useGetProductByIdQuery } = productApiSlice