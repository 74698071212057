import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        allCartData: [],
        total: ''
    },
    reducers: {
        setCart: (state, action) => {
            const grouped = action?.payload?.allCartData?.reduce((result, product) => {
                const existingProduct = result.find((p) => p._id === product._id);

                if (existingProduct) {
                    existingProduct.quantity += 1;
                } else {
                    result.push({ ...product, quantity: 1 });
                }

                return result;
            }, []);
            state.allCartData = [...grouped]
            state.total = action.payload.total
        },
        updateCart: (state, action) => {
            const { id, quantity } = action.payload;
            const updatedCartData = state.allCartData.map(item => {
                if (item._id === id) {
                    
                    return { ...item, quantity };
                }
                return item; 
            });
            state.allCartData = updatedCartData;
        }

    }
})

export default cartSlice.reducer
export const { setCart, updateCart } = cartSlice.actions
export const currentCart = (state) => state.cart.allCartData
export const currentTotal = (state) => state.cart.total