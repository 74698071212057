/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Button from '../components/button/Button'
import { useNavigate } from 'react-router-dom'
import "../App.css"
import { usePlaceOrderMutation } from '../api/cart/cartApiSlice'
import { useDispatch } from 'react-redux'
import { setCart } from '../features/cart/cartSlice'
import { setLoading, unsetLoading } from '../features/utils/loadingSlice'
import { showNotification } from '../features/notifications/notificationSlice'
const CheckedOut = () => {
    const navigate = useNavigate('')
    const [placeOrder, { error, isError, isLoading }] = usePlaceOrderMutation()
    const dispatch = useDispatch()
    useEffect(() => {
        const place = async () => {
            try {
                const { data } = await placeOrder()
                dispatch(setCart({ allCartData: data?.result?.cart }))
            } catch (error) {

            }
        }
        place()
    }, [])
    useEffect(() => {
        isLoading ? dispatch(setLoading()) : dispatch(unsetLoading())
        isError && dispatch(showNotification({ severity: 'error', message: error?.data?.message }))
    }, [isError, error, isLoading])
    return (
        <>
            <div
                className='checked-out'
            >
                <p
                    style={{
                        fontSize: '5rem'
                    }}
                >
                    🎉
                </p>
                <h3>
                    Order is placed successfully!
                </h3>
                <small>
                    You will be receiving a confirmation email with order details.
                </small>
                <Button
                    title={'Go back to Home page'}
                    width={'50%'}
                    onClick={(e) => {
                        e.preventDefault()
                        navigate('/')
                    }}
                />
            </div>
        </>
    )
}

export default CheckedOut