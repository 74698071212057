import React from 'react'
import "./Invoice.css"
import Button from '../../button/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { currentCart } from '../../../features/cart/cartSlice'
const Invoice = () => {
    const products = useSelector(currentCart)
    const navigate = useNavigate('')
    return (
        <div
            className='invoice'
        >
            <Button
                title={'Place your order'}
                backgroundColor={"#FFD600"}
                color={"#000"}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/checked-out')
                }}
            />
            <small>
                By placing your order, you agree to Musicart privacy notice and conditions of use.
            </small>
            <hr />
            <b>
                Order Summary
            </b>
            <p

                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <span>Items Total</span>
                <span>₹{products.reduce((total, product) => {
                    return +total + +product.price * +product.quantity;
                }, 0)}</span>
            </p>
            <p

                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <span
                >Delivery</span>
                <span
                >45</span>
            </p>
            <hr
                style={{
                    marginTop: 'auto'
                }}
            />
            <b

                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#B52B00'
                }}>
                <span>
                    Order Total
                </span>
                <span>
                    {
                        products.reduce((total, product) => {
                            return +total + +product.price * +product.quantity;
                        }, 0) + 45
                    }
                </span>
            </b>
        </div>
    )
}

export default Invoice