import React from 'react'
import { BsBag } from 'react-icons/bs'
import CartContainer from '../components/containers/cart/CartContainer'
import ItemList from '../components/cart/itemList/ItemList'
import Bill from '../components/cart/bill/Bill'
import "../App.css"
import { useSelector } from 'react-redux'
import { currentCart } from '../features/cart/cartSlice'
const Cart = () => {
    const products = useSelector(currentCart)
    return (


        <>
            <h2
                className='my-cart'
            >
                <BsBag />  My Cart
            </h2>
            <CartContainer>
                {products.length > 0 ? <>
                    <ItemList />
                    <Bill />
                </> : <p>No Products Added</p>}

            </CartContainer>

        </>
    )
}

export default Cart