/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './FilterDropdowns.css';
import { useDispatch, useSelector } from 'react-redux';
import { currentFilters, setFilter } from '../../../features/products/filterSlice';

const FilterDropdowns = () => {
    const filters = useSelector(currentFilters)
    const { type, color, brand } = filters
    const dispatch = useDispatch()

    const handleSelectChange = (event) => {
        const { name, value } = event.target
        if (name === 'price') {
            let prices = { priceLow: 0, priceHigh: 10000000 }
            if (value === "2") prices = { priceLow: 1000, priceHigh: 10000 };
            if (value === "1") prices = { priceLow: 0, priceHigh: 1000 };
            if (value === "3") prices = { priceLow: 10000, priceHigh: 20000 };
            dispatch(setFilter({ ...filters, price: prices }))
            return
        }
        dispatch(setFilter({ ...filters, [name]: value }))
    };

    return (
        <div className="filter-dropdowns">
            <select name='type' value={type} onChange={handleSelectChange}>
                <option value="">Headphone Type</option>
                <option value="in">In-ear headphone</option>
                <option value="on">On-ear headphone</option>
                <option value="over">Over-ear headphone</option>
            </select>

            <select name='brand' value={brand} onChange={handleSelectChange}>
                <option value="">Company</option>
                <option value="boAt">boAt</option>
                <option value="JBL">JBL</option>
                <option value="Jabra">Jabra</option>
                <option value="Philips">Philips</option>
                <option value="Infinity">Infinity</option>
                <option value="Hammer">Hammer</option>
                <option value={'Sennheiser'}>Sennheiser</option>
                <option value={'CrossBeats'}>CrossBeats</option>
                <option value={'Sony'}>Sony</option>
            </select>

            <select name='color' value={color} onChange={handleSelectChange}>
                <option value="">Color</option>
                <option value="black">Black</option>
                <option value="brown">Brown</option>
                <option value="white">White</option>
                <option value="brown">Brown</option>
                <option value="grey">Grey</option>
                <option value="green">Green</option>

            </select>

            <select name='price' onChange={handleSelectChange}>
                <option value={'0'} > Price</option>
                <option value={'1'}>₹0 - ₹1,000</option>
                <option value={'2'}>₹1,000 - ₹10,000</option>
                <option value={'3'}>₹10,000 - ₹20,000</option>
            </select>
        </div >
    );
};

export default FilterDropdowns;
