/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import ProductCarousel from '../../miscellaneous/carousel/ProductCarousel'
import Button from '../../button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import "./ViewProduct.css"
import { useGetProductByIdQuery } from '../../../api/product/productApiSlice'
import { showNotification } from '../../../features/notifications/notificationSlice'
import { setLoading, unsetLoading } from '../../../features/utils/loadingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAddToCartMutation } from '../../../api/cart/cartApiSlice'
import { setCart } from '../../../features/cart/cartSlice'
import { currentToken } from '../../../features/auth/authSlice'
const ViewProduct = () => {
    const { id } = useParams()
    const { data: product, isLoading, isError, error } = useGetProductByIdQuery(id)
    const [addToCart] = useAddToCartMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(currentToken)
    useEffect(() => {
        isError && dispatch(showNotification({ message: error?.data?.message, severity: 'error' }))
        isLoading ? dispatch(setLoading()) : dispatch(unsetLoading())
    }, [error, isError, isLoading])
    if (isLoading) return <p>loading...</p>
    const handleAdd = async () => {
        if (!token) navigate('/login')
        try {
            const { data } = await addToCart(id)
            data && dispatch(showNotification({ message: data?.message, severity: 'success' }))
            data && dispatch(setCart({ allCartData: data?.result?.cart }))

        } catch (error) {

        }
    }
    return (
        <>
            <div
                style={{
                    width: '100%',
                }}
            >
                <Button
                    onClick={() => {
                        navigate('/')
                    }} width={'8rem'} title={'Back to Products'} />
            </div>
            <div
                style={{
                    width: '100%',
                    fontWeight: 500,
                    fontSize: '1rem',
                    wordWrap: 'break-word'

                }}
            >
                <p>
                    {product?.result?.longDescription}
                </p>
            </div>
            <div
                style={{

                }}
                className='product-container'
            >
                <div
                    style={{

                    }}
                    className='carousel-box'>
                    <ProductCarousel
                        data={product?.result?.images.map(x => ({ image: x }))}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        wordBreak: 'break-word'
                    }}
                    className='info-box'>
                    <p
                        style={{
                            fontSize: "1.5rem",
                            fontWeight: 500,
                            marginBottom: '1rem'
                        }}
                    >
                        {product?.result?.name}
                    </p>
                    <p>
                        {'⭐⭐⭐⭐⭐'.slice(0, product?.result?.rating)}{" "}{`(${product?.result?.reviews} reviews)`}
                    </p>
                    <b>
                        {`Price -  ₹${product?.result?.price}`}
                    </b>
                    <p>
                        {`${product?.result?.color} | ${product?.result?.type} headphone`}
                    </p>
                    <b>
                        About this item
                    </b>
                    <ul
                        style={{
                            wordBreak: 'break-word',
                            marginLeft: '2rem'
                        }}
                    >
                        {
                            product?.result?.about?.map(x => (
                                <li
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                                    {x}
                                </li>
                            ))
                        }
                    </ul>
                    <p
                    >
                        <b>
                            Available -
                        </b>
                        <span>
                            {product?.result?.isAvailable ? 'Yes' : 'No'}
                        </span>
                    </p>
                    <p
                    >
                        <b>
                            Featured -
                        </b>
                        <span>
                            {product?.result?.isFeatured ? 'Yes' : 'No'}
                        </span>
                    </p>
                    <Button
                        onClick={() => { handleAdd() }}
                        title={'Add to cart'}
                        backgroundColor={'#FFD600'}
                        color={'#333'}
                        width={'100%'}
                        borderRadius={'3rem'}
                    />
                    <Button
                        onClick={() => { handleAdd(); navigate("/cart") }}
                        title={'Buy Now'}
                        backgroundColor={'#FFB800'}
                        color={'#333'}
                        width={'100%'}
                        borderRadius={'3rem'}
                    />
                </div>
            </div>
        </>
    )
}

export default ViewProduct