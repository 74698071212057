import React, { useState } from 'react';
import './SortDropdown.css';
import { useDispatch, useSelector } from 'react-redux';
import { currentFilters, setSort } from '../../../features/products/filterSlice';

const SortDropdown = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const filters = useSelector(currentFilters)
    const dispatch = useDispatch()
    const handleSelectChange = (event) => {
        const { value } = event.target
        let sort = {}
        if (value === "0") sort = 'featured'
        if (value === "1") sort = 'nameAsc';
        if (value === "2") sort = 'nameDesc'
        if (value === "3") sort = 'priceAsc';
        if (value === "4") sort = 'priceDesc';
        setSelectedOption(value);
        dispatch(setSort({ ...filters, sort }))

    };

    return (
        <div className="sort-dropdowns">
            <select className='sort' value={selectedOption} onChange={handleSelectChange}>
                <option value="0">Sort by featured</option>
                <option value="1">Sort: A-Z </option>
                <option value="2">Sort: Z-A</option>
                <option value="3">Sort: Price increasing </option>
                <option value="4">Sort: Price decreasing</option>
            </select>
        </div>
    );
};

export default SortDropdown;
