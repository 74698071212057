import React from 'react'
import './ItemDetail.css'
const ItemDetail = ({ item }) => {
    return (
        <div
            className='item-detail-item-detail'
        >
            <div
                style={{
                    backgroundImage: `url(${item.images[0]})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}
                className='image-box-item-detail'
            >

            </div>
            <b>
                {item?.name}
            </b>
            <small
                style={{
                    color: 'rgba(0,0,0,0.75)'
                }}
            >
                Color: {item?.color}
            </small>
            <small
                style={{
                    color: 'rgba(0,0,0,0.75)'
                }}
            >
                In Stock
            </small>
            <small
                style={{
                    color: 'rgba(0,0,0,0.75)'
                }}>
                Quantity : {item?.quantity}
            </small>
        </div>
    )
}

export default ItemDetail