import React, { useEffect } from 'react'
import Product from './product/Product'
import "./ProductsList.css"
import { useGetProductsQuery } from "../../../api/product/productApiSlice"
import { useDispatch, useSelector } from 'react-redux'
import { currentFilters } from '../../../features/products/filterSlice'
import { showNotification } from '../../../features/notifications/notificationSlice'
import { setLoading, unsetLoading } from '../../../features/utils/loadingSlice'
const ProductsList = () => {
    const filter = useSelector(currentFilters)
    const { color, brand, price, type, sort, name } = filter
    const dispatch = useDispatch()
    const { data, isLoading, isError, error } = useGetProductsQuery({ color, brand, type, price, sort, name })
    useEffect(() => {
        if (error) {
            dispatch(showNotification({ message: error?.data?.message, severity: 'error' }))
        }
        isLoading ? dispatch(setLoading()) : dispatch(unsetLoading())
    }, [isError, error, isLoading, dispatch])
    if (isLoading) return
    return (
        <div
            className='product-list'
        >
            {
                data?.result?.length > 0 ? data?.result?.map(x =>
                (<>
                    <Product
                        key={x?._id}
                        background={x?.images[0]}
                        type={x?.type}
                        color={x?.color}
                        price={x?.price}
                        name={x?.name}
                        longDescription={x?.longDescription}
                        id={x?._id}
                    />
                </>)
                ) : <>
                    {

                        <p>
                            No data found!
                        </p>
                    }
                </>
            }
        </div>
    )
}

export default ProductsList