import React from 'react'
import './SearchBar.css'
import { useDispatch } from 'react-redux'
import { setSearch } from '../../../features/products/filterSlice'
const SearchBar = () => {
    const dispatch = useDispatch()
    return (
        <div
            className='search'
        >
            <input
                onChange={(e) => {
                    dispatch(setSearch({ name: e.target.value }))
                }}
                style={{
                    padding: '0.8rem'
                }}
                placeholder={'🔍 Search For Products'}

            />
        </div>
    )
}

export default SearchBar