import React from 'react'
import CartContainer from '../components/containers/cart/CartContainer'
import Button from '../components/button/Button'
import Details from '../components/checkout/details/Details'
import Invoice from '../components/checkout/invoice/Invoice'
import "../App.css"
import { useSelector } from 'react-redux'
import { currentCart } from '../features/cart/cartSlice'
const Checkout = () => {
    const products = useSelector(currentCart)
    return (
        <>
            <div
                style={{
                    marginRight: 'auto',
                    width: '100%'
                }}
            >
                <Button
                    width={'8rem'}
                    title={'Go back to cart'}

                />
            </div>
            <h2
                className='checkout-title'
            >
                Checkout
            </h2>
            <CartContainer>
                {products.length > 0 ? <>
                    <Details />
                    <Invoice />
                </> : <>

                    <p>
                        No items
                    </p></>}
            </CartContainer >
        </>
    )
}

export default Checkout