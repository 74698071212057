import { cartApiRoute } from "../../constants/appConstants";
import { apiSlice } from "../base/apiSlice";

export const cartApiSlice = apiSlice.injectEndpoints({

    endpoints: (builder) => ({
        addToCart: builder.mutation({
            query: (id) => ({
                url: `${cartApiRoute}/addToCart?productId=${id}`,
                method: 'PATCH'
            })
        }),
        getCart: builder.mutation({
            query: () => ({
                url: `${cartApiRoute}/getCart`,
                method: 'GET'
            })
        }),
        placeOrder: builder.mutation({
            query: () => ({
                url: `${cartApiRoute}/placeOrder`,
                method: 'PATCH'
            })
        })

    })
})

export const { useAddToCartMutation, useGetCartMutation, usePlaceOrderMutation } = cartApiSlice