import React from 'react'
import "./Item.css"
import { useDispatch, useSelector, } from 'react-redux'
import { currentCart, updateCart } from '../../../../features/cart/cartSlice'
const Item = ({ item }) => {
    const dispatch = useDispatch()
    const cart = useSelector(currentCart)
    return (
        <div
            className='item'
        >
            <div
                style={{
                    backgroundImage: `url(${item?.images[0]})`
                }}
                className='image-box'
            >

            </div>

            <div
                className='item-data'>
                <b>
                    {item?.name}
                </b>
                <small

                >
                    Colour : {item?.color}
                </small>
                <small

                >
                    {item?.isAvailable ? "In Stock" : "Out of stock"}
                </small>
            </div>
            <div
                className='item-data'>
                <b>
                    Price
                </b>
                <small

                >
                    ₹{item?.price}
                </small>
            </div>
            <div
                className='item-data'>
                <b>
                    Quantity
                </b>
                <select
                    onChange={(e) => {
                        dispatch(updateCart({ id: item?._id, quantity: e.target.value }))
                    }}
                    value={item?.quantity}
                    style={{
                        padding: "0.2rem",
                        borderRadius: '0.2rem'

                    }}
                >
                    {
                        [1, 2, 3, 4, 5, 6].map(x => (
                            <option value={x} >{x}</option>
                        ))

                    }
                </select>

            </div>
            <div
                className='item-data'>
                <b>
                    Total
                </b>
                <small

                >
                    ₹{item?.price * item?.quantity}
                </small>
            </div>
        </div>

    )
}

export default Item