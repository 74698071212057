/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from 'react'
import "./ItemList.css"
import Item from './item/Item'
import Button from '../../button/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { currentCart } from '../../../features/cart/cartSlice'
const ItemList = () => {
    const products = useSelector(currentCart)
    const navigate = useNavigate()
    return (
        <div
            className='item-list'
        >
            {
                products?.map(x => {


                    return <Item key={x} item={x} />
                }


                )
            }
            <hr />
            <div
                className='total-bottom'
            >
                <b>
                    {products?.length} items
                </b>
                <p>
                    ₹{products.reduce((total, product) => {
                        return +total + +product.price * +product.quantity;
                    }, 0)}
                </p>

            </div>
            <div
                className='hide-ingig'
            >
                <Button
                    onClick={() => {
                        navigate("/checkout")
                    }}
                    width={'100%'}
                    title={'Place order'}
                    backgroundColor={'#FFD600'}
                    color={"#000"}
                />
            </div>
        </div>
    )
}

export default ItemList