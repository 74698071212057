import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        type: '',
        brand: '',
        color: '',
        price: '',
        sort: 'featured',
        name: ''

    },
    reducers: {
        setFilter: (state, action) => {
            state.type = action.payload.type
            state.brand = action.payload.brand
            state.color = action.payload.color
            state.price = (action.payload.price)

        },
        setSort: (state, action) => {
            state.sort = action?.payload?.sort || 'featured'
        },
        setSearch: (state, action) => {
            state.name = action.payload.name
        }
    }
})

export default filterSlice.reducer
export const { setFilter, setSort, setSearch } = filterSlice.actions
export const currentFilters = (state) => state.filter