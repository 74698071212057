import React from 'react'
import "./Footer.css"
const Footer = () => {
    return (
        <p className='footer'>
            Musicart | All rights reserved
        </p>
    )
}

export default Footer