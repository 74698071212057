/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import "./Navbar.css"
import { BsFillFileEarmarkMusicFill } from 'react-icons/bs'
import Button from '../button/Button'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetCartMutation } from '../../api/cart/cartApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { currentCart, setCart } from '../../features/cart/cartSlice'
import { setLoading, unsetLoading } from '../../features/utils/loadingSlice'
import { showNotification } from '../../features/notifications/notificationSlice'
const Navbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const handleClick = () => {
        navigate('/cart')
    }
    const cartData = useSelector(currentCart)
    const [getCart, { isLoading, isError, error }] = useGetCartMutation()
    const dispatch = useDispatch()
    useEffect(() => {

        const fetch = async () => {
            try {
                const { data } = await getCart()
                dispatch(setCart({ allCartData: data.result.cart }))
            } catch (error) {

            }
        }
        fetch()
    }, [])
    useEffect(() => {
        isLoading ? dispatch(setLoading()) : dispatch(unsetLoading())
        isError && dispatch(showNotification({ message: error?.data?.message, severity: 'error' }))

    }, [isError, isLoading, error])
    useEffect(() => {

    })
    if (isLoading) return <p>loading...</p>
    return (
        <div
            className='navbar'
        >
            <div>
                <p
                    style={{
                        color: '#2E0052',
                        fontSize: "1.5rem"
                    }}>
                    <BsFillFileEarmarkMusicFill
                        style={{ marginRight: '0.5rem', color: '#E794CE ' }} />
                    Musicart
                </p>
            </div>
            <div>
                {
                    !location.pathname.includes('cart') &&
                    <Button
                        title={`View Cart ${cartData.length}`}
                        padding={'0.5rem 1.2rem'}
                        backgroundColor={'#1D7000'}
                        leftIcon={<AiOutlineShoppingCart />}
                        borderRadius={'3rem'}
                        onClick={handleClick}
                    />}
            </div>
        </div >
    )
}

export default Navbar